import { getCurrentEvent } from 'selectors/event'
import mixpanel from 'shared-components/utils/mixpanel'
import { mixpanelTrackEventState } from '../utils/useMixpanelTrackEvent'

export const OPEN_MODAL = 'OPEN_MODAL'
export const OPEN_MODAL_CHAT = 'OPEN_MODAL_CHAT'
export const OPEN_MODAL_SIGNIN = 'OPEN_MODAL_SIGNIN'
export const CLOSE_MODAL = 'CLOSE_MODAL'
export const ADD_IMAGE_CHAT_MODAL = 'ADD_IMAGE_CHAT_MODAL'
export const SET_IMAGES_CHAT_MODAL = 'SET_IMAGES_CHAT_MODAL'
export const SET_EVENT_UPDATES_MODAL = 'SET_EVENT_UPDATES_MODAL'
export const OPEN_DATE_OPTION_RESPONSES = 'OPEN_DATE_OPTION_RESPONSES'
export const TOGGLE_SEARCH = 'TOGGLE_SEARCH'
export const OPEN_MODAL_PAGE = 'OPEN_MODAL_PAGE'
export const SET_MODAL_PAGE = 'SET_MODAL_PAGE'
export const SET_MODAL_TEXT_KEY = 'SET_MODAL_TEST_KEY'
export const SET_DOWNLOAD_USE_CONTINUE_IN_APP =
  'SET_DOWNLOAD_USE_CONTINUE_IN_APP'
export const OPEN_GUEST_INFO = 'OPEN_GUEST_INFO'
export const OPEN_USER_INFO = 'OPEN_USER_INFO'
export const OPEN_OPEN_IN_APP = 'OPEN_OPEN_IN_APP'
export const SHOW_ANIM = 'SHOW_ANIM'
export const REMOVE_ANIM = 'REMOVE_ANIM'
export const SET_EDITING_POST = 'SET_EDITING_POST'

export const openModal = (modal) => ({
  type: OPEN_MODAL,
  payload: modal,
})

export const showAnim = (anim) => ({
  type: SHOW_ANIM,
  payload: anim,
})

export const removeAnim = (anim) => ({
  type: REMOVE_ANIM,
  payload: anim,
})

export const openModalOnPage = (modal, page) => ({
  type: OPEN_MODAL_PAGE,
  payload: {
    modal,
    page,
  },
})

export const setModalTextKey = (modal, key) => ({
  type: SET_MODAL_TEXT_KEY,
  payload: {
    modal,
    key,
  },
})

export const setDownloadUseContinueInApp = (useContinueInApp) => ({
  type: SET_DOWNLOAD_USE_CONTINUE_IN_APP,
  payload: {
    useContinueInApp,
  },
})

export const setModalPage = (modal, page) => ({
  type: SET_MODAL_PAGE,
  payload: {
    modal,
    page,
  },
})

export const closeModal = (modal) => ({
  type: CLOSE_MODAL,
  payload: modal,
})

export const addImageChatModal = (images) => ({
  type: ADD_IMAGE_CHAT_MODAL,
  payload: images,
})

export const setEventUpdatesModal = (eventUpdates) => ({
  type: SET_EVENT_UPDATES_MODAL,
  payload: eventUpdates,
})

export const setImagesChatModal = (images) => ({
  type: SET_IMAGES_CHAT_MODAL,
  payload: images,
})

export const toggleSearch = (toggle) => ({
  type: TOGGLE_SEARCH,
  payload: toggle,
})

export const openFeedbackModal = () => {
  mixpanel.track('Click Give Feedback')
  return openModal('feedback')
}
export const closeFeedbackModal = () => {
  return closeModal('feedback')
}

export const openTicketsModal = () => {
  return openModal('tickets')
}
export const closeTicketsModal = () => {
  return closeModal('tickets')
}

export const openGuestModal = () => (dispatch, getState) => {
  const state = getState()
  mixpanelTrackEventState('Check Guest List', getCurrentEvent(state), {}, state)
  dispatch(openModal('guest'))
}
export const closeGuestModal = () => {
  return closeModal('guest')
}

export const openDownloadModal =
  (transactional = true, useContinueInApp = true) =>
  (dispatch) => {
    // mixpanel.track('Open Download Dialog', { action })
    // dispatch(setModalTextKey('download', keys || {
    //   title: {
    //     key: 'downloadDialogEvent.title',
    //     options: null
    //   },
    //   description: {
    //     key: 'downloadDialogEvent.description',
    //     options: null
    //   }
    // }))

    if (transactional) {
      dispatch(
        setModalTextKey('download', {
          title: {
            key: 'downloadDialogTransactional.title',
            options: null,
          },
          description: {
            key: 'downloadDialogTransactional.description',
            options: null,
          },
        }),
      )
    } else {
      dispatch(
        setModalTextKey('download', {
          title: {
            key: 'downloadDialogEvent.title',
            options: null,
          },
          description: {
            key: 'downloadDialogEvent.description',
            options: null,
          },
        }),
      )
    }

    dispatch(setDownloadUseContinueInApp(useContinueInApp))
    dispatch(openModal('download'))
  }

export const openDownloadModalRequest = (eventName) => (dispatch) => {
  // mixpanel.track('Open Download Dialog', { action })
  // dispatch(setModalTextKey('download', keys || {
  //   title: {
  //     key: 'downloadDialogEvent.title',
  //     options: null
  //   },
  //   description: {
  //     key: 'downloadDialogEvent.description',
  //     options: null
  //   }
  // }))

  dispatch(
    setModalTextKey('download', {
      title: {
        key: 'downloadDialogRequest.title',
        options: { eventName },
      },
      description: {
        key: 'downloadDialogRequest.description',
        options: { eventName },
      },
    }),
  )

  dispatch(setDownloadUseContinueInApp(true))
  dispatch(openModal('download'))
}

export const closeDownloadModal = () => {
  // mixpanel.track('Close Download Dialog')
  return closeModal('download')
}

export const openFollowModal = () => {
  mixpanel.track('Open Follow Dialog')
  return openModal('follow')
}

export const openFollowUserModal = () => {
  mixpanel.track('Open Follow User Dialog')
  return openModal('followUser')
}

export const openVerifyEmailModal = () => {
  return openModal('verifyEmail')
}

export const closeVerifyEmailModal = () => {
  return closeModal('verifyEmail')
}

export const openMessageModal = () => {
  return openModal('message')
}

export const openCalendarModal = () => {
  mixpanel.track('Open Add To Calendar Dialog')
  return openModal('addToCalendar')
}

export const closeCalendarModal = () => {
  return closeModal('addToCalendar')
}

export const closeMessageModal = () => {
  return closeModal('message')
}

export const closeFollowModal = () => {
  mixpanel.track('Close Follow Dialog')
  return closeModal('follow')
}

export const closeFollowUserModal = () => {
  mixpanel.track('Close Follow User Dialog')
  return closeModal('followUser')
}

export const openRsvpModal = () => {
  return openModal('rsvp')
}

export const closeRsvpModal = () => {
  return closeModal('rsvp')
}

export const openPinDateModal = () => {
  return openModal('pinDate')
}

export const closePinDateModal = () => {
  return closeModal('pinDate')
}

export const openPrivateEventModal = () => {
  return openModal('privateEvent')
}

export const closePrivateEventModal = () => {
  return closeModal('privateEvent')
}

export const openReachabilityModal = () => {
  return openModal('reachability')
}

export const closeReachabilityModal = () => {
  return closeModal('reachability')
}

export const openSignInModal = (emailAddress, organisationToFollow) => {
  mixpanel.track('Start Sign In')
  return {
    type: OPEN_MODAL_SIGNIN,
    payload: {
      modal: 'signIn',
      emailAddress,
      organisationToFollow,
    },
  }
}

export const openSignInModalOnEmail = () => {
  mixpanel.track('Start Sign In')
  return openModalOnPage('signIn', 1)
}

export const closeSignInModal = () => {
  return closeModal('signIn')
}

export const openInviteGuestsModal = () => {
  return openModal('inviteGuests')
}

export const closeInviteGuestsModal = () => {
  return closeModal('inviteGuests')
}

export const openShareSheet = () => {
  return openModal('share')
}

export const closeShareSheet = () => {
  return closeModal('share')
}

export const openScanner = () => {
  return openModal('scanner')
}

export const closeScanner = () => {
  return closeModal('scanner')
}

export const openCreatePostModal = (
  autoFocusImageInput = false,
  replyTo = null,
  imageFiles = [],
) => ({
  type: OPEN_MODAL_CHAT,
  payload: {
    modal: 'chat',
    autoFocusImageInput: true,
    replyTo: replyTo,
    imageFiles: imageFiles,
  },
})

export const closeChatModal = () => {
  return closeModal('chat')
}

export const openDateOptionResponses = (dateOptionId) => ({
  type: OPEN_DATE_OPTION_RESPONSES,
  payload: dateOptionId,
})

export const closeDateOptionsResponses = () => {
  return closeModal('dateOptions')
}

export const openGuestInfo = (guest) => ({
  type: OPEN_GUEST_INFO,
  payload: guest,
})

export const closeGuestInfo = () => {
  return closeModal('guestInfo')
}

export const openGuestAnswers = () => {
  return openModal('guestAnswers')
}

export const closeGuestAnswers = () => {
  return closeModal('guestAnswers')
}

export const openStripePayment = () => {
  return openModal('stripePayment')
}

export const closeStripePayment = () => {
  return closeModal('stripePayment')
}

export const openUserInfo = (user) => ({
  type: OPEN_USER_INFO,
  payload: user,
})

export const closeUserInfo = () => {
  return closeModal('userInfo')
}

export const setEditingPost = (post) => ({
  type: SET_EDITING_POST,
  payload: post,
})

export const openOpenInApp = (user) => ({
  type: OPEN_OPEN_IN_APP,
  payload: user,
})

export const openCreatePage = () => {
  return openModal('createPage')
}

export const closeCreatePage = () => {
  return closeModal('createPage')
}

export const closeOpenInApp = () => {
  return closeModal('openInApp')
}
export const openTicketStats = () => {
  return openModal('ticketStats')
}
export const closeTicketStats = () => {
  return closeModal('ticketStats')
}

export const showBee = () => {
  return showAnim('bee')
}

export const removeBee = () => {
  return removeAnim('bee')
}
